import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Spinner,
  Fade,
  Toast,ToastBody,ToastHeader
} from "reactstrap";
import Avatar from 'react-avatar';
import axios from 'axios';
import moment from 'moment';
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: 1,
      activeIndex: 0,
      sharpsList: [],
      subscriptionsCount: 0,
      subscriptions: [],
      isLoading: true,
      showToast: false,
      toastMessage: '',
      toastType: '',
      user: {
        id: '',
        card: {},
        subscriptions: []
      },
    };
  }
  
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log("auth user in nav: " + uid)
        this.state.user.id = uid
        //this.refetchUserInfo()
        // ...
      } else {
        this.props.history.push({
          pathname: '/',
          state: { user: {} }
        })
      }
    });
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("profile-page");

    // Retrieve list of sharps
    axios.post('/subscriptions', {uid: this.props.location.state.userInfo.id})
      .then(resp => {
        this.setState({subscriptionsCount: resp.data.count, subscriptions: resp.data.sharps, isLoading: false})
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }



  componentWillUnmount() {
    document.body.classList.remove("profile-page");
  }

  refetchSubscriptions = () => {
    this.setState({isLoading: true})
    // Retrieve list of sharps
    axios.post('/subscriptions', {uid: this.props.location.state.userInfo.id})
      .then(resp => {
        this.setState({subscriptionsCount: resp.data.count, subscriptions: resp.data.sharps, isLoading: false})
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }

  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };

  refetchUserInfo = () => {
    this.setState({isLoading: true})

    axios.post('/user', {uid: this.state.user.id})
      .then(resp => {
        this.setState({isLoading: false, user: resp.data, subscriptions: resp.data.subscriptions})
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }

  //Handle logout
  handleLogout = () => {
    firebase.auth().signOut().then(() => {
      this.setState({user: {}})
      this.props.history.push({
        pathname: '/',
        state: { user: {} }
      })
    }).catch((error) => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: error.message,
        showToast: true
      })
    });
  }

  handleProfile = () => {
    this.props.history.push({
      pathname: '/profile',
      state: { userInfo: this.state.user }
    })
  }

  handleHome = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleUsers = () => {
    this.props.history.push({
      pathname: '/users',
      state: { userInfo: this.state.user }
    })
  }

  // Calculate win percentage from all bets
  sharpWinPercentage = (bets) => {
    let winCount = 0;
    let lossCount = 0;
    bets.forEach((bet) => {
      if (bet.is_win) winCount++
      if (bet.is_loss) lossCount++
    })

    return (<span>{((parseFloat((winCount/(winCount + lossCount)) *100).toFixed(2))).toString()}</span>)
  }

  // Calculate accumulilation of all bet totals
  sharpTotalBetAmount = (bets) => {
    let totalAmount = 0;

    bets.forEach((bet) => {
      totalAmount = totalAmount+bet.bet_amount
    })

    return parseFloat(totalAmount/100).toFixed(2)
  }

  totalSubscriptionCost = (subscriptions) => {
    let totalAmount = 0;
    subscriptions.forEach((sharp) => {
      totalAmount = totalAmount+sharp.subscription_price
    })
    return this.convertToCurrency(totalAmount)
  }

  // Calculate average bet amount
  sharpAvgBetAmount = (bets) => {
    let totalAmount = 0;

    bets.forEach((bet) => {
      totalAmount = totalAmount+bet.bet_amount
    })

    return parseFloat((totalAmount/bets.length)/100).toFixed(2)
  }

  convertToCurrency = (number) => {
    if(isNaN(parseFloat(number))){
        return "N/A"
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    return formatter.format(number)
  }

    // Calculate ROI amount across all bets
    ROIAmount = (sharp) => {  
      let roiAmount = sharp.bank_total - sharp.starting_bank_total 
  
      return roiAmount;
    }

  // Get Sharp current bankroll
  sharpBankRoll = (bets) => {
    let bankroll = 0;

    bankroll = bets[bets.length - 1].bankroll;

    return parseFloat(bankroll/100).toFixed(2)
  }

  handleUnsubscribe = (sharpID) => {
    this.setState({isLoading: true})

    axios.post('/unsubscribe', {UID: this.props.location.state.userInfo.id, sharpID: sharpID})
     .then(resp => {
      this.setState({
        isLoading: false,
        toastType: 'Success',
        toastMessage: resp.data,
        showToast: true
      })

      this.refetchSubscriptions()

      setTimeout(()=>{
        this.setState({showToast: false});
      }, 3700)
     })
     .catch(err => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: err.message,
        showToast: true
      })
      
      setTimeout(()=>{
        this.setState({showToast: false});
      }, 3700)
     })
  }

  convertUserCreatedTime = () => {
    let t = new Date(1970, 0, 1); // Epoch
    
    t.setSeconds(this.props.location.state.userInfo.created_at._seconds);

    return (<span>{moment(t.toDateString()).format('MM/DD/YYYY')}</span>);
  }

  render() {
    return (
      <>
        {
          this.state.isLoading &&
          <>
            <div className="loading-window">
              <Card>
                <CardBody>
                  <Spinner color="primary" />
                </CardBody>
              </Card>
            </div>
          </>
        }
        <Fade in={this.state.showToast}>
          <Toast className="float-right">
            <ToastHeader icon={(this.state.toastType.toLowerCase() ==='success')?'success':'danger'}>
              {this.state.toastType}
            </ToastHeader>
            <ToastBody>
              {this.state.toastMessage}
            </ToastBody>
          </Toast>
        </Fade>
        <ColorNavbar title = "< Back"  home={this.handleHome} userInfo={this.props.location.state.userInfo} users={this.handleUsers} logout={this.handleLogout} profile={this.handleProfile}/>
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <Container className="align-items-left margin-neg-10">
              <Row>
                <Col lg="8" sm="12">
                  <Card className="card profile-card">
                    <CardHeader></CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6" lg="3">
                          <Avatar name={this.props.location.state.userInfo.first_name+" "+this.props.location.state.userInfo.last_name} />
                        </Col>
                        <Col sm="6" lg="6">
                          <Row>
                            <h4 className="title">{this.props.location.state.userInfo.first_name+" "+this.props.location.state.userInfo.last_name}</h4>
                          </Row>
                          <Row>
                            <Col className="text-right join-date"><small>Joined: {this.convertUserCreatedTime()}</small></Col>
                          </Row>
                          <Row className="mt-2">
                            <Col sm="6" className="text-left">
                              <span>Subscriptions Total:</span>
                            </Col>
                            <Col sm="6" className="text-right">
                              <b className="text-info">{this.state.subscriptionsCount}</b>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col sm="6" className="text-left">
                              <span>Monthly Payments:</span>
                            </Col>
                            <Col sm="6" className="text-right">
                              <b className="text-info">{this.totalSubscriptionCost(this.state.subscriptions)}</b>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section p-0">
            <Container className="margin-neg-5">
              <Row>
                <Col><h3>Subscriptions</h3></Col>
              </Row>
                <Row>
                  {
                    this.state.subscriptions.map((sharp, ind) => {
                      return (
                          <Col md="3" key={"sharp_"+ind}>
                            <Card className="card-profile">
                              <div className="card-image">
                                <h4 className="title">{sharp.first_name+" "+sharp.last_name}</h4>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img img-raised rounded"
                                    src={sharp.img_url}
                                    width="256"
                                    height="256"
                                  />
                                </a>
                              </div>
                              <CardBody>
                                <hr className="line-primary" />
                                {/* <h3 className="job-title">Bets: {sharp.bets.length}</h3> */}
                                <Row>
                                  <Col className="text-right join-date"><small>Joined: {moment(sharp.created_at).format('MM/DD/YYYY')}</small></Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Table className="tablesorter" responsive>
                                      <tbody>
                                        <tr>
                                          <td className="text-left">
                                            <i className="tim-icons icon-atom" />
                                            Current Bankroll:
                                          </td>
                                          { <td className="text-right">{this.convertToCurrency(sharp.bank_total)}</td> }
                                        </tr>
                                        <tr>
                                          <td className="text-left">
                                            <i className="tim-icons icon-atom" />
                                            Starting Bankroll:
                                          </td>
                                          { <td className="text-right">{this.convertToCurrency(sharp.starting_bank_total)}</td> }
                                        </tr>
                                        <tr>
                                          <td className="text-left">
                                            <i className="tim-icons icon-atom" />
                                            ROI Amount:
                                          </td>
                                          <td className="text-right">{this.convertToCurrency(this.ROIAmount(sharp))}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col className="text-center">
                                    {/* <Button color="info" */}
                                    {/*         onClick={()=>{ */}
                                    {/*           this.onViewSelectSharp(sharp) */}
                                    {/*         }}>View</Button> */}
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col className="text-center">
                                    <Button color="warning"
                                            onClick={(e)=>{
                                              this.handleUnsubscribe(sharp.id)
                                            }}>Unsubscribe</Button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                      )
                    })
                  }
                </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default ProfilePage;
