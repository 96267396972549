import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Spinner,
  Fade,
  Toast,ToastBody,ToastHeader,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Avatar from 'react-avatar';
import axios from 'axios';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import moment from 'moment';
import CSVReader from 'react-csv-reader'
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload";


class BetsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: 1,
      activeIndex: 0,
      subscriptionsCount: 0,
      subscriptions: [],
      isLoading: true,
      showToast: false,
      toastMessage: '',
      toastType: '',
      sharpsList: [],
      image: null,
      url: "",
      sendBetModalOpen: false,
      betEditModalOpen: false,
      betDeleteModalOpen: false,
      selectedSharp: this.props.location.state.sharp,
      selectedResult: '',
      selectedBet:{},
      sharpCreationObj: {},
      betSheet: [],
      user: {
        id: '',
        card: {},
        subscriptions: []
      },
    };
  }
  
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log("auth user in nav: " + uid)
        this.state.user.id = uid
        this.refetchUserInfo()
        // ...
      } else {
        this.props.history.push({
          pathname: '/',
          state: { user: {} }
        })
      }
    });
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("users-page");

    // Retrieve list of sharps
  }

  componentWillUnmount() {
    document.body.classList.remove("profile-page");
  }

  refetchUserInfo = () => {
    this.setState({isLoading: true})

    axios.post('/user', {uid: this.state.user.id})
      .then(resp => {
        let user  = resp.data
        if(user.is_admin){
          this.setState({isLoading: false, user: resp.data, subscriptions: resp.data.subscriptions})
        }else{
          this.props.history.push({
            pathname: '/',
            state: { user: {} }
          })
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }

  refetchSharpInfo = () => {
    this.setState({isLoading: true})

    axios.post('/sharp', {id: this.state.selectedSharp.id})
      .then(resp => {
        this.setState({isLoading: false, selectedSharp: resp.data})
      })
      .catch(err => {
        console.log(err)
      })
  }



  //Handle logout
  handleLogout = () => {
    firebase.auth().signOut().then(() => {
      this.setState({user: {}})
      this.props.history.push({
        pathname: '/',
        state: { user: {} }
      })
    }).catch((error) => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: error.message,
        showToast: true
      })
    });
  }

  handleHome = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleBetUpdate = () => {
    this.toggleBetEditModal();
    this.setState({isLoading: true});

    axios.post('/update_bet', this.state.selectedBet)
      .then(resp => {
        this.setState({
          toastType: 'Success',
          toastMessage: resp.data,
          showToast: true,
          isLoading: false
        })
        this.refetchSharpInfo()
        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
      .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
  }

  handleUsers = () => {
    this.props.history.push({
      pathname: '/users',
      state: { userInfo: this.state.user }
    })
  }

  handleProfile = () => {
    this.props.history.push({
      pathname: '/profile',
      state: { userInfo: this.state.user }
    })
  }

  handleBetDelete = (bet) => {
    this.toggleBetDeleteModal();
    this.setState({isLoading: true});

    axios.post('/delete_bet', bet)
      .then(resp => {
        this.setState({
          toastType: 'Success',
          toastMessage: resp.data,
          showToast: true
        })

        this.refetchSharpInfo()
        
        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
      .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
  }

  allUnsentBets  = (bets) => {
    let unsentBets = []
    bets.forEach((bet) => {
        if (!bet.send_email) {
            unsentBets.push(bet)
        }
      })
      return unsentBets
  }

  handleSendBets = (bets) => {
    this.toggleSendBetsModal();
    this.setState({isLoading: true});
    axios.post('/send_bets', {bets: bets, sharp: this.state.selectedSharp})
      .then(resp => {
        this.setState({
          toastType: 'Success',
          toastMessage: resp.data,
          showToast: true
        })

        this.refetchSharpInfo()
        
        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
      .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
  }


  toggleBetEditModal = () => {
    const {betEditModalOpen} = this.state;

    (betEditModalOpen) ? this.setState({betEditModalOpen: false}) : this.setState({betEditModalOpen: true})
  }

  toggleBetDeleteModal = () => {
    const {betDeleteModalOpen} = this.state;

    (betDeleteModalOpen) ? this.setState({betDeleteModalOpen: false}) : this.setState({betDeleteModalOpen: true})
  }

  toggleSendBetsModal = () => {
    const {sendBetModalOpen} = this.state;

    (sendBetModalOpen) ? this.setState({sendBetModalOpen: false}) : this.setState({sendBetModalOpen: true})
  }

  onEditSelectBet= (bet) => {
    const { selectedBet } = this.state;

    this.setState({selectedBet: {...selectedBet, ...bet}}, () => {
      this.toggleBetEditModal()
    })
  }

  onDeleteSelectBet = (bet) => {
    const { selectedBet } = this.state;

    this.setState({selectedBet: {...selectedBet, ...bet}}, () => {
      this.toggleBetDeleteModal()
    })
  }

  convertToCurrency = (number) => {
    if(isNaN(parseFloat(number))){
        return "N/A"
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    return formatter.format(number)
  }

  

  convertToDateString = (timestamp) => {
    var date = new Date(timestamp._seconds * 1000)
    console.log(date.toString())
    // var result = ((date.getMonth().toString().length > 1) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))
    //  + '/' + ((date.getDate().toString().length > 1) ? date.getDate() : ('0' + date.getDate()))
    //  + '/' + date.getFullYear() 

     var result = moment(date).format('MM/DD/YYYY')
     return result
  }

  betResultStatus = (bet) => {
    if(bet.is_open){
        return "Open"
    }else if(bet.is_win){
        return "Win"
    }else if(bet.is_loss){
        return "Loss"
    }else if(bet.is_cancel){
        return "Cancelled"
    }else if(bet.is_push){
        return "Push"
    }
    return "N/A"

  }

  betStatusChanged = (status) => {
    var bet = this.state.selectedBet
    // this.setState({
    //     selectedBet: {
    //       ...this.state.selectedBet,
    //       is_win: false,
    //       is_open: false,
    //       is_loss: false,
    //       is_cancel: false,
    //       is_push: false
    //     }
    //   });
    bet.is_win = false;
    bet.is_open = false;
    bet.is_loss = false;
    bet.is_cancel = false;
    bet.is_push = false;
    switch(status) {
        case "Win":
            // this.setState({
            //     selectedBet: {
            //       ...this.state.selectedBet,
            //       is_win: true,
            //       result: this.state.selectedBet.potential_payout,
            //       bank_total: this.state.selectedBet.result + this.state.selectedSharp.bank_total
            //     }
            //   });
          bet.is_win = true;
          bet.result = bet.potential_payout
          bet.bank_total = bet.result + this.state.selectedSharp.bank_total
          break;
        case "Loss":
            bet.is_loss = true
            bet.result = -bet.bet_amount
            bet.bank_total = bet.result + this.state.selectedSharp.bank_total
          break;
        case "Open":
            bet.is_open = true
            bet.result = 0
            bet.bank_total = bet.result + this.state.selectedSharp.bank_total
          break;
        case "Cancel":
            bet.is_cancel = true
            bet.result = 0
            bet.bank_total = bet.result + this.state.selectedSharp.bank_total
          break;
        case "Push":
            bet.is_push = true
            bet.result = 0
            bet.bank_total = bet.result + this.state.selectedSharp.bank_total
          break;
        default:
          // code block
      }
      this.setState({selectedBet: bet})
  }

  sharpWinPercentage = (bets) => {
    let winCount = 0;

    bets.forEach((bet) => {
      if (bet.is_win) winCount++
    })

    return (<span>{((parseFloat(winCount/bets.length).toFixed(2))*100).toString()}</span>)
  }

  sharpUnsentBets = (bets) => {
    let unsentBets = 0;

    bets.forEach((bet) => {
      if (!bet.send_email) unsentBets++
    })

    return unsentBets.toString()
  }

  betDetails = (bet) => {
    return <span>{bet.team + " - " + bet.opponent + " " + bet.odds}</span>
  }

  

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          .toLowerCase()
          .replace(/\W/g, '_')
    }

    return (
      <>
        {
          this.state.isLoading &&
          <>
            <div className="loading-window">
              <Card>
                <CardBody>
                  <Spinner color="primary" />
                </CardBody>
              </Card>
            </div>
          </>
        }
        <Fade in={this.state.showToast}>
          <Toast className="float-right">
            <ToastHeader icon={(this.state.toastType.toLowerCase() ==='success')?'success':'danger'}>
              {this.state.toastType}
            </ToastHeader>
            <ToastBody>
              {this.state.toastMessage}
            </ToastBody>
          </Toast>
        </Fade>
        <ColorNavbar title = "< Back" home={this.handleHome} userInfo={this.state.user} logout={this.handleLogout} profile={this.handleProfile} users={this.handleUsers} bets={this.handleBets} />
        <div className="wrapper mt-4" ref="wrapper">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">{this.state.selectedSharp.first_name + " " + this.state.selectedSharp.last_name + " Bets"}</h2>
            </Col>
          </Row>
          <Col>
            {/* <Row>
              <Col>
                <h2>{this.state.selectedSharp.first_name + " " + this.state.selectedSharp.last_name + " Bets"}</h2>
              </Col>
            </Row> */}
            <Row>
              <Col className="text-right">
                <Button color="info" className="mr-4" onClick={() =>{this.toggleSendBetsModal()}}><span>{"Send " + this.sharpUnsentBets(this.state.selectedSharp.bets) + " Bets" }</span></Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="12">
                <ReactTable
                  data={this.state.selectedSharp.bets}
                  className="-striped -highlight bg-secondary p-2"
                  columns={[
                    {
                        Header: "Bet Details",
                        sort: true,
                        headerClassName: 'text-left font-weight-bold',
                        className: " text-left font-weight-bold",
                        Cell: row => {
                          return(
                            this.betDetails(row.original)
                          );
                        }
                      },
                    {
                      Header: "Bet Date",
                      sort: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center",
                      Cell: row => {
                        return(
                          <span>{this.convertToDateString(row.original.date)}</span>
                        );
                      }
                    },
                      {
                        Header: "Sport",
                        accessor:"sport",
                        sort: true,
                        headerClassName: 'text-center font-weight-bold',
                        className: " text-center"
                      },
                      {
                        Header: "Bet Type",
                        accessor:"bet_type",
                        sort: true,
                        headerClassName: 'text-center font-weight-bold',
                        className: " text-center"
                      },
                        
                    {
                      Header: "Bet Amount",
                      sort: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center",
                      Cell: row => {
                        return(
                            <span>{this.convertToCurrency(row.original.bet_amount)}</span>
                        );
                      }
                    },
                    {
                      Header: "Pot Payout",
                      sort: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center",
                      Cell: row => {
                        return(
                            <span>{this.convertToCurrency(row.original.potential_payout)}</span>
                        );
                      }
                    },
                    {
                      Header: "Bet Sent",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center ",
                      Cell: row => {
                        return(
                            <span>{row.original.send_email ? 'Sent' : 'Pending' }</span>
                        );
                      }
                    },
                    {
                        Header: "Result",
                        sortable: true,
                        headerClassName: 'text-center font-weight-bold',
                        className: " text-center ",
                        Cell: row => {
                          return(
                              <span>{this.betResultStatus(row.original)}</span>
                          );
                        }
                      },
                      {
                        Header: "$ Result",
                        sortable: true,
                        headerClassName: 'text-center font-weight-bold',
                        className: " text-center ",
                        Cell: row => {
                            return(
                                <span>{this.convertToCurrency(row.original.result)}</span>
                            );
                        }
                      },
                      {
                        Header: "Bankroll",
                        sortable: true,
                        headerClassName: 'text-center font-weight-bold',
                        className: " text-center ",
                        Cell: row => {
                            return(
                                <span>{this.convertToCurrency(row.original.bank_total)}</span>
                            );
                        }
                      },
                    {
                      id: "Actions",
                      className: "text-center",
                      Cell: row => {
                        return(
                          <>
                          <Button color="info" size="sm" onClick={(e) => {
                            e.stopPropagation()
                            this.onEditSelectBet(row.original)
                          }}>Result</Button>
                          <Button color="danger ml-2" size="sm" onClick={(e) => {
                            e.stopPropagation()
                            this.onDeleteSelectBet(row.original)
                          }}><i className="tim-icons icon-simple-remove"></i></Button>
                          </>
                        );
                      }
                    }
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </div>

        <Modal isOpen={this.state.sendBetModalOpen} toggle={this.sendBetModalOpen} modalClassName="modal-black">
          <ModalHeader toggle={this.sendBetModalOpen}>{"Send " + this.sharpUnsentBets(this.state.selectedSharp.bets) + " Bets?"} </ModalHeader>
          <ModalBody className="mt-4">
            <Card>
              <h2 className="text-center">Are you sure you want to send</h2>
              <h1 className="text-center"><span className="text-danger">{this.sharpUnsentBets(this.state.selectedSharp.bets)}</span> bets?</h1>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Col className="text-center">
              <Button color="secondary" size="sm" className="mr-1" onClick={()=>{this.toggleSendBetsModal()}}>Cancel</Button>
              <Button color="primary" size="sm" className="ml-1" onClick={() =>{this.handleSendBets(this.allUnsentBets(this.state.selectedSharp.bets))}}>Confirm</Button>
            </Col>
          </ModalFooter>
        </Modal>


        <Modal isOpen={this.state.betEditModalOpen} toggle={this.toggleBetEditModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleBetEditModal}>Edit Result</ModalHeader>
          <ModalBody className="mt-4">
            <Card>
              <Row>
                <Col><span className="float-left">Bet Amount:</span> <span className="float-right">{this.convertToCurrency(this.state.selectedBet.bet_amount)}</span></Col>
              </Row> 
              <Row>
                <Col><span className="float-left">Win Amount:</span> <span className="float-right">{this.convertToCurrency(this.state.selectedBet.potential_payout)}</span></Col>
              </Row> 
              <Row>
                <Col><span className="float-left">Current Bankroll:</span> <span className="float-right">{this.convertToCurrency(this.state.selectedSharp.bank_total)}</span></Col>
              </Row> 
              <Row>
                <Col><span className="float-left">Resulting Bankroll:</span> <span className="float-right">{this.convertToCurrency(this.state.selectedBet.bank_total)}</span></Col>
              </Row> 
              <Row>
                <Col>
                  <Form>
                     <FormGroup>
                        <Label>Result</Label>
                        <Input type="select" onChange={(e) => {
                            this.betStatusChanged(e.target.value)
                        }}>
                            <option>Open</option>
                            <option>Win</option>
                            <option>Loss</option>
                            <option>Cancel</option>
                            <option>Push</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                      <Col className="text-right">
                        <Button color="secondary" size="sm" onClick={()=>{this.toggleBetEditModal()}}>Cancel</Button>
                        <Button color="primary" size="sm" className="ml-2" onClick={()=>{
                            this.handleBetUpdate()
                          }}>Submit</Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Card>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.betDeleteModalOpen} toggle={this.toggleBetDeleteModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleBetDeleteModal}></ModalHeader>
          <ModalBody className="mt-4">
            <Card>
              <h2 className="text-center">Are you sure you want to remove</h2>
              <h1 className="text-center"><span className="text-danger">{this.betDetails(this.state.selectedBet)}</span> ? </h1>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Col className="text-center">
              <Button color="secondary" size="sm" className="mr-1" onClick={()=>{this.toggleBetDeleteModal()}}>Cancel</Button>
              <Button color="primary" size="sm" className="ml-1" onClick={() =>{this.handleBetDelete(this.state.selectedBet)}}>Confirm</Button>
            </Col>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default BetsPage;