import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class ColorNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navbarColor: "navbar-transparent"
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);

  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
  }
  changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      this.setState({
        navbarColor: "navbar-transparent"
      });
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      this.setState({
        navbarColor: "navbar-transparent"
      });
    }
  };

  render() {
    let authRender = "";
    
    if (this.props.hasOwnProperty('userInfo')
      && this.props.userInfo !== undefined
      && this.props.userInfo.hasOwnProperty('email')
      && this.props.userInfo.email.length > 1) {
        if(this.props.userInfo.is_admin){
                authRender =  <UncontrolledButtonDropdown>
                                <DropdownToggle caret>
                                  {this.props.userInfo.first_name+" "+this.props.userInfo.last_name}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={this.props.profile}>Profile</DropdownItem>
                                  <DropdownItem onClick={this.props.users}>Admin</DropdownItem>
                                  <DropdownItem onClick={this.props.logout}>Log out</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
      }else{
                authRender =  <UncontrolledButtonDropdown>
                        <DropdownToggle caret>
                          {this.props.userInfo.first_name+" "+this.props.userInfo.last_name}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={this.props.profile}>Profile</DropdownItem>
                          <DropdownItem onClick={this.props.logout}>Log out</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
      }
    } else {
      authRender =  <Button className="nav-link"
                    color="default"
                    size="sm"
                    href="/login"><p>Log in</p></Button>
    }

    return (
      <>
        <Navbar className={this.state.navbarColor} expand="lg">
            <NavbarBrand href="#pablo" onClick={e => this.props.home(e)}>{this.props.title}</NavbarBrand>
            <Nav className="ml-auto" navbar>
              <NavItem>
                {authRender}
              </NavItem>
            </Nav>
        </Navbar>
      </>
    );
  }
}

export default ColorNavbar;
