import React from "react";
import axios from 'axios';
// Firebase packages
import * as firebase from "firebase/app";
import "firebase/auth";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Fade,
  Toast,
  ToastBody,
  ToastHeader,
  Spinner,
  Row
} from "reactstrap";

// core components
//import DemoFooter from "components/Footers/DemoFooter.jsx";

// Initialize Firebase
const firebaseConf = require('config/firebase');
firebase.initializeApp(firebaseConf.development);

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      showToast: false,
      toastMessage: '',
      toastType: '',
      isLoading: false,
      isRegister: false
    }
  }

  login = () => {
    this.setState({isLoading: true})

    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((resp) => {
        let userObj = {
          email: resp.user.email,
          uid: resp.user.uid
        }

        // Get more user info
        axios.post('/user', {uid: userObj.uid})
          .then(resp => {
            this.setState({isLoading: false})

            this.props.history.push({
              pathname: '/index',
              state: { user: {...resp.data, ...{id: userObj.uid}} }
            })
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              toastType: 'Error',
              toastMessage: err.message,
              showToast: true
            })

            setTimeout(()=>{
              this.setState({showToast: false});
            }, 3700)
          })

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: error.message,
          showToast: true
        })

        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)

      });
  }

  // Render registration form
  renderRegisterForm = () => {
    this.setState({isRegister: true})
  }

  // Register new user
  registerUser = () => {
    this.setState({isLoading: true})

    // Use firebase Auth to register user to auth table
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(resp => {
        // Update First Name and Last Fields to Users Collection
         axios.post('/create_user', {uid: resp.user.uid, firstName: this.state.firstName, lastName: this.state.lastName, isAdmin: false, email: this.state.email})
           .then((resp) => {
             this.setState({
               isLoading: false,
               toastType: 'Success',
               toastMessage: resp.data,
               showToast: true
             })

             setTimeout(()=>{
               this.setState({showToast: false, isRegister:false});
             }, 3700)
           })
           .catch(err => {
             this.setState({
               isLoading: false,
               toastType: 'Error',
               toastMessage: err.message,
               showToast: true
             })

             setTimeout(()=>{
               this.setState({showToast: false});
             }, 3700)
           })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: error.message,
          showToast: true
        })

        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      });
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("login-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
  }

  render() {
    return (
      <>
        {
          this.state.isLoading &&
          <>
            <div className="loading-window">
              <Card>
                <CardBody>
                  <Spinner color="primary" />
                </CardBody>
              </Card>
            </div>
          </>
        }
        <Fade in={this.state.showToast}>
          <Toast className="float-right">
            <ToastHeader icon={(this.state.toastType.toLowerCase() ==='success')?'success':'danger'}>
              {this.state.toastType}
            </ToastHeader>
            <ToastBody>
              {this.state.toastMessage}
            </ToastBody>
          </Toast>
        </Fade>
        <div className="page-header">
          <div className="page-header-image" />
          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <Card className="card-login">
                <Form action="" className="form" method="">
                  <CardHeader
                  className="text-center"

                  >
                    <CardTitle tag="h3">Sportfolio</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {
                      !this.state.isRegister &&
                      <>
                        <InputGroup
                          className={classnames("input-lg", {
                            "input-group-focus": this.state.emailFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            onFocus={e => this.setState({ emailFocus: true })}
                            onBlur={e => this.setState({ emailFocus: false })}
                            onChange={e => this.setState({ email: e.target.value })}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames("input-lg", {
                            "input-group-focus": this.state.passwordFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="tim-icons icon-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            onFocus={e => this.setState({ passwordFocus: true })}
                            onBlur={e => this.setState({ passwordFocus: false })}
                            onChange={e => this.setState({ password: e.target.value })}
                          />
                        </InputGroup>
                      </>
                    }
                    {
                      this.state.isRegister &&
                      <>
                        <InputGroup
                          className={classnames("input-lg", {
                            "input-group-focus": this.state.firstNameFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="First Name"
                            type="text"
                            onFocus={e => this.setState({ firstNameFocus: true })}
                            onBlur={e => this.setState({ firstNameFocus: false })}
                            onChange={e => this.setState({ firstName: e.target.value })}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames("input-lg", {
                            "input-group-focus": this.state.lastNameFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Last Name"
                            type="text"
                            onFocus={e => this.setState({ lastNameFocus: true })}
                            onBlur={e => this.setState({ lastNameFocus: false })}
                            onChange={e => this.setState({ lastName: e.target.value })}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames("input-lg", {
                            "input-group-focus": this.state.emailFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            onFocus={e => this.setState({ emailFocus: true })}
                            onBlur={e => this.setState({ emailFocus: false })}
                            onChange={e => this.setState({ email: e.target.value })}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames("input-lg", {
                            "input-group-focus": this.state.passwordFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="tim-icons icon-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            onFocus={e => this.setState({ passwordFocus: true })}
                            onBlur={e => this.setState({ passwordFocus: false })}
                            onChange={e => this.setState({ password: e.target.value })}
                          />
                        </InputGroup>
                        <Row>
                          <Col className="text-right">
                            <a
                              className="link footer-link"
                              href="#"
                              onClick={()=>{this.setState({isRegister: false})}}
                            >
                              <small>Go Back</small>
                            </a>
                          </Col>
                        </Row>
                      </>
                    }
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="secondary"
                      onClick={(!this.state.isRegister) ? this.login : this.registerUser}
                      size="lg"
                    >
                      {(!this.state.isRegister) ? 'Login' : 'Register'}
                    </Button>
                    <div className="mb-3">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#register"
                          onClick={this.renderRegisterForm}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div>
                  </CardFooter>

                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default LoginPage;
