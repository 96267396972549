import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.0.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// pages
import Index from "views/Index.jsx";
import ProfilePage from "views/pages/ProfilePage.jsx";
import UsersPage from "views/pages/UsersPage.jsx";
import Error404 from "views/pages/Error404.jsx";
import Error500 from "views/pages/Error500.jsx";
import LoginPage from "views/pages/LoginPage.jsx";
import axios from 'axios';
import BetsPage from "views/pages/BetsPage";

const firebaseConf = require('config/firebase');

// Axios Configs
axios.defaults.baseURL = firebaseConf.apiURL;
axios.get('/').then((resp)=>{console.log(resp.data)})

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={props => <Index {...props} />} />
      <Route
        path="/profile"
        render={props => <ProfilePage {...props} />}
      />
      <Route
        path="/users"
        render={props => <UsersPage {...props} />}
      />
      <Route
        path="/bets"
        render={props => <BetsPage {...props} />}
      />
      <Route path="/404-error" render={props => <Error404 {...props} />} />
      <Route path="/500-error" render={props => <Error500 {...props} />} />
      <Route path="/login" render={props => <LoginPage {...props} />} />
      <Redirect from="/" to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
