/*
  Default Home view
*/
import React from "react";
import axios from 'axios';
import * as firebase from "firebase/app";
import defaultAvatar from "assets/img/placeholder.jpg";
import "firebase/auth";
import moment from 'moment';

// Stripe stuff
import {Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";

// reactStrap
import {
  Button, ButtonGroup,
  Card,CardHeader,CardBody,
  Table,
  Container,
  Row,
  Col,
  Nav, NavItem, NavLink,
  Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Fade,
  Toast,ToastBody,ToastHeader,
  Spinner,
  ListGroup, ListGroupItem
} from "reactstrap";

import { Line } from 'react-chartjs-2';

// Stripe configuration
//const stripePromise = loadStripe('pk_test_UHO1WBDC3MJgqMk8hHejzehq00BzWBnapY'); //test stripe account
const stripePromise = loadStripe('pk_live_51HXujXLdM5QEKmQ80cKKGXEImF2NsdYn1dBQerdjn5VjnfdFwEHmvLwzQM9wXM67WR062Bmv6gDpVR6vnYKa37sY00TuPhIsqg'); //product stripe account
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#ffffff',
      fontSmoothing: 'antialiased',
      fontFamily: '"Poppins", sans-serif',
      ':-webkit-autofill': {color: '#fce883'},
      '::placeholder': {color: '#87bbfd'},
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const debounce = (func, wait, immediate) => {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // Backend update paymenttype to user
      axios.post('/attach-user-card', {customer_id: this.props.customer.stripe_customer_id, token: paymentMethod.id })
        .then(resp => {

          const reqObj = {
            userEmail: this.props.customer.email,
            userID: this.props.customer.id,
            sharpID: this.props.sharpID,
          }

          this.props.subscribe();
        })
        .catch(err => {
          console.log(err)
        })
    }

  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Row>
          <Col>
            <Input type="text"
                  placeholder="name as it appears on card" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Input type="text"
                  placeholder="Address line 1" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Input type="text"
                  placeholder="Address line 2" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <CardElement className="form-control" options={CARD_OPTIONS} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-right">
            <Button size="sm" color="success" onClick={this.handleSubmit}>Subscribe</Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <CheckoutForm elements={elements} stripe={stripe} customer={props.userInfo} sharpID={props.sharpID} subscribe={props.subscribe} />
      )}
    </ElementsConsumer>
  );
};

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sharpViewModalOpen: false,
      sharpSubscribeModalOpen: false,
      selectedSharp: {
        bets: []
      },
      sharpsList: [],
      sportType: [
        {id: 0, name: "MLB"},
        {id: 1, name: "NBA"},
        {id: 2, name: "NFL"},
        {id: 3, name: "NCAAB"},
        {id: 4, name: "EPL"}
      ],
      betTypes: [
        {id: 0, name: "Overall"},
        {id: 1, name: "Over"},
        {id: 2, name: "Under"},
        {id: 3, name: "Runline/Spread"},
        {id: 4, name: "Moneyline"}
      ],
      dateRangeTypes: [
        {id: 0, name: "Overall"},
        {id: 1, name: "YTD"},
        {id: 2, name: "Last Month"},
        {id: 3, name: "MTD"},
        {id: 4, name: "Yesterday"}
      ],
      user: {
        id: '',
        card: {},
        subscriptions: []
      },
      showToast: false,
      toastMessage: '',
      toastType: '',
      isLoading: true,
      userHasDefaultPayment: false,
      userNewCardInfo: {},
      userCardInfo: {},
      isUserSubscribed: false,
      subscriptions: [],
      sharpSearchText: '',
      unFilteredSharpsList: [],
      selectedSportsFilter: 'all',
      sortType: ''
   }

  }


  // Refetch User Info
  refetchUserInfo = () => {
    this.setState({isLoading: true})
    var uid = this.state.user.id;
    axios.post('/user', {uid: uid})
      .then(resp => {
        this.setState({isLoading: false, user: resp.data, subscriptions: resp.data.subscriptions})
        this.state.user.id = uid
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }

  refetchSharpsList = () => {
    this.setState({isLoading: true})
    
    axios.get('/bets')
      .then(resp => {
        this.setState({sharpsList: resp.data})
        this.setState({isLoading: false})
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }

  toggleSharpViewModal = () => {
    const {sharpViewModalOpen} = this.state;

    (sharpViewModalOpen) ? this.setState({sharpViewModalOpen: false}) : this.setState({sharpViewModalOpen: true})
  }

  toggleSharpSubscribeModal = () => {
    const {sharpSubscribeModalOpen} = this.state;

    (sharpSubscribeModalOpen) ? this.setState({sharpSubscribeModalOpen: false}) : this.setState({sharpSubscribeModalOpen: true})
  }

  onViewSelectSharp = (sharp) => {
    const { selectedSharp } = this.state;

    this.setState({selectedSharp: {...selectedSharp, ...sharp}}, () => {
      this.toggleSharpViewModal()
    })
  }

  onSubscribeSelectSharp = (sharp) => {
    const { selectedSharp } = this.state;

    if (this.state.user.id.length <= 1) {
      this.props.history.push('/login')
    } else {
      axios.post('/validate_payment_method', {uid: this.state.user.id, cust_id: this.state.user.stripe_customer_id})
        .then(resp => {
          if (resp.data.hasPaymentMethod) {
            this.setState({userCardInfo: resp.data.card.card, userHasDefaultPayment: true})
          } else {
            this.setState({userHasDefaultPayment: false})
          }

          this.setState({selectedSharp: {...selectedSharp, ...sharp}}, () => {
            this.toggleSharpSubscribeModal()
          })
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })
          
          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
    }
  }

  // Calculate win percentage from all bets
  sharpWinPercentage = (bets) => {
    let winCount = 0;
    let lossCount = 0;
    bets.forEach((bet) => {
      if (bet.is_win) winCount++
      if (bet.is_loss) lossCount++
    })

    return (<span>{((parseFloat((winCount/(winCount + lossCount)) *100).toFixed(2))).toString()}</span>)
  }

  // Used for Sorting Win Percentage
  sharpWinPercentSort = (bets) => {
    let winCount = 0;
    let lossCount = 0;

    bets.forEach((bet) => {
      if (bet.is_win) winCount++
      if (bet.is_loss) lossCount++
    })

    return parseFloat(winCount/bets.length).toFixed(2)*100
  }

  // Calculate accumulilation of all bet totals
  sharpTotalBetAmount = (bets) => {
    let totalAmount = 0;

    bets.forEach((bet) => {
      if (bet.is_win || bet.is_loss){
        totalAmount = totalAmount + bet.bet_amount
      } 
    })

    return this.convertToCurrency(totalAmount)
  }

  convertToCurrency = (number) => {
    if(isNaN(parseFloat(number))){
        return "N/A"
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    return formatter.format(number)
  }

  // Calculate ROI amount across all bets
  ROIAmount = (sharp) => {
    // let totalAmount = 0,
    //     winAmount = 0,
    //     roiAmount = 0;

    // bets.forEach((bet) => {
    //   totalAmount = totalAmount+bet.bet_amount
    //   winAmount = winAmount+bet.result
    // })

    let roiAmount = sharp.bank_total - sharp.starting_bank_total 

    return roiAmount;
  }

  // Calculate ROI amount across of all bets
  ROIPercent = (sharp) => {
    let bets = sharp.bets

    let totalAmount = 0;

    bets.forEach((bet) => {
      if (bet.is_win || bet.is_loss){
        totalAmount = totalAmount + bet.bet_amount
      } 
    })

    let roiTotal = this.ROIAmount(sharp)
    let startingBankTotal = sharp.starting_bank_total

    return parseFloat((roiTotal/startingBankTotal)*100).toFixed(2);
  }

  // Calculate average bet amount
  sharpAvgBetAmount = (bets) => {
    let totalAmount = 0;

    bets.forEach((bet) => {
      totalAmount = totalAmount+bet.bet_amount
    })

    return this.convertToCurrency(totalAmount/bets.length)
  }

  // Get Sharp current bankroll
  sharpBankRoll = (bets) => {
    let bankroll = 0;

    bankroll = bets[bets.length - 1].bankroll;

    return parseFloat(bankroll/100).toFixed(2)
  }

  sharpFilteredSportsBets = (bets) => {
    const filteredBets = bets.filter(bet => {
      return bet.sport === this.state.selectedSportsFilter
    });

    if (this.state.selectedSportsFilter === 'all') {
      return bets.length
    } else {
      return filteredBets.length
    }

  }

  data = (canvas) => {
      var ctx = canvas.getContext("2d");

      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, '#80b6f4');
      gradientStroke.addColorStop(1, '#FFFFFF');

      var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");

      var lineChartBetsLabel = () => {
        let betLabels = [];

        //this.state.selectedSharp.bets.forEach((bet, i)=> {
        //  betLabels.push(i.toString())
        //})
        betLabels = ["Bruins v Sabres - 9/11/18", "Lakers v Heats - 2/4/19", "Lakers v Nets - 3/24/19", "Knicks v Bulls - 5/13/19", "Sabres v Penguins - 5/18/19",
                    "Bruins v Penguins - 6/13/19", "Celtics v Spurs - 6/18/19", "Knicks v Celtics - 6/21/19", "Jazz v Bulls - 6/22/19", "Mavericks v Heat - 6/30/19",
                    "Raptors v Knicks - 7/02/19", "Nets v Jazz - 7/3/19", "Bulls v Knicks 7/11/19", "Bills v Dolphins - 7/14/19", "Browns v Steelers - 7/14/19",
                    "Packers v Giants - 8/16/19", "Bills v Packers - 9/19/19", "Eagles v Seahawks - 9/30/19", "Rockets v Bulls - 10/10/19", "Yankees v Red Socks - 11/3/19",
                    "Eagles v Giants - 12/18/19", "Manchester United v Chelsea - 1/11/20", "Dortmund v Panathanaikos - 1/15/20", "Psv v Marseille - 1/16/20", "Liverpool v Rangers - 2/18/20"]

        return betLabels
      }

      var lineChartBetData = () => {
        let bankRoll = [];

        //this.state.selectedSharp.bets.forEach((bet, i)=> {
        //  bankRoll.push(bet.bankroll/100)
        //})
        bankRoll = this.state.selectedSharp.bank_total

        return bankRoll
      }
      return {
          labels: lineChartBetsLabel(),
          datasets: [{
              label: "Bank total",
              borderColor: "#3B95F9",
              pointBorderColor: "#FFF",
              pointBackgroundColor: "#f96332",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 1,
              pointRadius: 4,
              fill: true,
              backgroundColor: gradientFill,
              borderWidth: 2,
              data: lineChartBetData()
          }]
      }
  };

  options = {
      maintainAspectRatio: false,
      legend: {
          display: false
      },
      tooltips: {
          bodySpacing: 4,
          mode:"nearest",
          intersect: 0,
          position:"nearest",
          xPadding:10,
          yPadding:10,
          caretPadding:10
      },
      responsive: 1,
      scales: {
          yAxes: [{
              display:0,
              ticks: {
                  display: false
              },
              gridLines: {
                  zeroLineColor: "transparent",
                  drawTicks: false,
                  display: false,
                  drawBorder: false
              }
          }],
          xAxes: [{
              display:0,
              ticks: {
                  display: false
              },
              gridLines: {
                  zeroLineColor: "transparent",
                  drawTicks: false,
                  display: false,
                  drawBorder: false
              }
          }]
      },
      layout:{
          padding:{left:0,right:0,top:15,bottom:15}
      }
  };

  // Filters Sharps by search
  filterBySearch = () => {
    const filteredSharps = [];

    if (this.state.sharpSearchText.length < 1) {
      this.setState({sharpsList: this.state.unFilteredSharpsList})
    }

    this.state.unFilteredSharpsList.forEach((sharp) =>{
      if (sharp.first_name.toLowerCase().includes(this.state.sharpSearchText.toLowerCase()) || sharp.last_name.toLowerCase().includes(this.state.sharpSearchText.toLowerCase())) {
        filteredSharps.push(sharp)
      }
    })
    
    if (filteredSharps.length === this.state.sharpsList.length) {
      return;
    } else {
      this.setState({sharpsList: filteredSharps})
    }
  }
  
  // Filters Sharps by Sports
  filterBySports = (sportName) => {
    const filteredSharps = [];

    this.setState({ selectedSportsFilter: sportName })

    if (sportName.toLowerCase() === 'all') {
      this.setState({sharpsList: this.state.unFilteredSharpsList})
    } else {
      this.state.unFilteredSharpsList.forEach((sharp) =>{
        for (var i = 0; i < sharp.bets.length; i++) {
          if (sharp.bets[i].sport.toLowerCase() === sportName.toLowerCase() ) {
            filteredSharps.push(sharp)
            break;
          }
        }     
      })

      if (filteredSharps.length === this.state.sharpsList.length) {
        return;
      } else {
        this.setState({sharpsList: filteredSharps})
      }
    }

  }

  // Handle subscription request
  handleSharpSubscribe = () => {
    this.setState({isLoading: true})

    // If user is logged in
    if (!this.state.user.hasOwnProperty('email')) {
      this.setState({isLoading: false})
      this.props.history.push('/login-page')
    } else {
      let reqObj = {
        sharpID: this.state.selectedSharp.id,
        userID: this.state.user.id,
        subscriptionID: this.state.selectedSharp.subscription_id,
        userEmail: this.state.user.email
      };

      // Post to create subscription
      axios.post('/subscribe', reqObj)
        .then(res=> {
          this.setState({
            isLoading: false,
            toastType: 'Success',
            toastMessage: res.data,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)

          this.toggleSharpSubscribeModal();
          this.refetchUserInfo()
          this.refetchSharpsList()
          
          // setTimeout(()=>{
          //   this.setState({showToast: false});
          // }, 3700)
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
    }
  }

  // Handler unsubscribing
  handleUnsubscribe = (sharpID) => {
    this.setState({isLoading: true})

    axios.post('/unsubscribe', {UID: this.state.user.id, sharpID: sharpID})
     .then(resp => {
      this.setState({
        isLoading: false,
        toastType: 'Success',
        toastMessage: resp.data,
        showToast: true
      })

      this.refetchUserInfo()
      this.refetchSharpsList()

      setTimeout(()=>{
        this.setState({showToast: false});
      }, 3700)
     })
     .catch(err => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: err.message,
        showToast: true
      })
      
      setTimeout(()=>{
        this.setState({showToast: false});
      }, 3700)
     })
  }

  // Sorting handler, set to descending
  handleSortSharp = (e) => {
    this.setState({sortType: e.target.value})

    switch(e.target.value) {
      case 'win_percent':
        let sortedWinsPercentSharpsList = [];

        this.state.sharpsList.forEach((sharp) => {
          let win_percentage = this.sharpWinPercentSort(sharp.bets);
          sortedWinsPercentSharpsList.push({...sharp, ...{win_percentage: win_percentage}})
        })

        sortedWinsPercentSharpsList = sortedWinsPercentSharpsList.sort((a,b) => {return b.win_percentage - a.win_percentage})

        this.setState({sharpsList: sortedWinsPercentSharpsList})
        break;
      case 'roi_amount':
        let sortedRoiAmountSharpsList = [];

        this.state.sharpsList.forEach((sharp) => {
          let roi_amount = this.ROIAmount(sharp);
          sortedRoiAmountSharpsList.push({...sharp, ...{roi_amount: roi_amount}})
        })

        sortedRoiAmountSharpsList = sortedRoiAmountSharpsList.sort((a,b) => {return b.roi_amount - a.roi_amount})

        this.setState({sharpsList: sortedRoiAmountSharpsList})
        break;
      case 'roi_percent':
        let sortedRoiPercentSharpsList = [];

        this.state.sharpsList.forEach((sharp) => {
          let roi_percent = this.ROIPercent(sharp);
          sortedRoiPercentSharpsList.push({...sharp, ...{roi_percent: roi_percent}})
        })

        sortedRoiPercentSharpsList = sortedRoiPercentSharpsList.sort((a,b) => {return b.roi_percent - a.roi_percent})

        this.setState({sharpsList: sortedRoiPercentSharpsList})
        break;
      case 'number_of_bets':
        let sortedNumberOfBetsSharpsList = [];

        this.state.sharpsList.forEach((sharp) => {
          let numberOfBets = sharp.bets.length;
          sortedNumberOfBetsSharpsList.push({...sharp, ...{number_of_bets: numberOfBets}})
        })

        sortedNumberOfBetsSharpsList = sortedNumberOfBetsSharpsList.sort((a,b) => {return b.number_of_bets - a.number_of_bets})

        this.setState({sharpsList: sortedNumberOfBetsSharpsList})
        break;
      case 'join_date':
        let sortedJoinedDateSharpsList = [];

        sortedJoinedDateSharpsList = this.state.sharpsList.sort((a,b) => {return a.created_at._seconds - b.created_at._seconds})

        this.setState({sharpsList: sortedJoinedDateSharpsList})
        break;
      default:
        this.setState({sharpsList: this.state.unFilteredSharpsList})
    }
  }

  //Handle logout
  handleLogout = () => {
    firebase.auth().signOut().then(() => {
      this.setState({user: {}})
      this.props.history.push({
        pathname: '/',
        state: { user: {} }
      })
    }).catch((error) => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: error.message,
        showToast: true
      })
    });
  }

  handleProfile = () => {
    this.props.history.push({
      pathname: '/profile',
      state: { userInfo: this.state.user }
    })
  }

  handleUsers = () => {
    this.props.history.push({
      pathname: '/users',
      state: { userInfo: this.state.user }
    })
  }

  hasSubscribed = (sharpID) => {
    if (this.state.subscriptions.includes(sharpID)) {
      return true
    } else {
      return false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.location.hasOwnProperty('state'))
      && (typeof nextProps.location.state === 'object')
      && (nextProps.location.state.hasOwnProperty('user'))) {
        let returnObj = {
          user: nextProps.location.state.user
        };

        return returnObj;
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log("auth user in nav: " + uid)
        this.state.user.id = uid
        this.refetchUserInfo()
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    document.body.classList.add("index-page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;

    if (this.state.user.id.length >= 1) {
      // Get accurate subscription list
      axios.post('/user', {uid: this.state.user.id})
        .then(resp => {
          this.setState({subscriptions: resp.data.subscriptions})

            // Retrieve list of sharps
            axios.get('/bets')
              .then(resp => {
                this.setState({sharpsList: resp.data, unFilteredSharpsList: resp.data})
                this.setState({isLoading: false})
              })
              .catch(err => {
                console.log(err)
              })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      // Retrieve list of sharps
      axios.get('/bets')
        .then(resp => {
          this.setState({sharpsList: resp.data, unFilteredSharpsList: resp.data})
          this.setState({isLoading: false})
        })
        .catch(err => {
          console.log(err)
        })
    }

    console.log('this.state.user: ', this.state.user);
  }

  componentWillUnmount() {
    document.body.classList.remove("index-page");
  }

  addDefaultSrc(ev){
    ev.target.src = defaultAvatar
  }

  render() {

    return (
      <>
        {
          this.state.isLoading &&
          <>
            <div className="loading-window">
              <Card>
                <CardBody>
                  <Spinner color="primary" />
                </CardBody>
              </Card>
            </div>
          </>
        }
        <Fade in={this.state.showToast}>
          <Toast className="float-right">
            <ToastHeader icon={(this.state.toastType.toLowerCase() ==='success')?'success':'danger'}>
              {this.state.toastType}
            </ToastHeader>
            <ToastBody>
              {this.state.toastMessage}
            </ToastBody>
          </Toast>
        </Fade>
        <ColorNavbar userInfo={this.state.user} logout={this.handleLogout} profile={this.handleProfile} users={this.handleUsers} />
        <div className="wrapper" ref="wrapper">
          <div className="team-2">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Sportfolio</h2>
                  <h4 className="description">
                    Integrity in Sports Forecasting!
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6" className="text-left">
                  <Nav tabs>
                      <NavItem onClick={(e)=>{this.filterBySports('all')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'all') ? true : false} href="#">All</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('MLB')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'MLB') ? true : false} href="#">MLB</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NBA')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NBA') ? true : false} href="#">NBA</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NFL')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NFL') ? true : false} href="#">NFL</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NHL')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NHL') ? true : false} href="#">NHL</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NCAAB')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NCAAB') ? true : false} href="#">NCAAB</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NCAAF')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NCAAF') ? true : false} href="#">NCAAF</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NCAAM')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NCAAM') ? true : false} href="#">NCAAM</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('NCAAW')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'NCAAW') ? true : false} href="#">NCAAW</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('MMA')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'MMA') ? true : false} href="#">MMA</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('Boxing')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'Boxing') ? true : false} href="#">Boxing</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('Tennis')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'Tennis') ? true : false} href="#">Tennis</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('Golf')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'Golf') ? true : false} href="#">Golf</NavLink>
                      </NavItem>
                      <NavItem onClick={(e)=>{this.filterBySports('Soccer')}}>
                          <NavLink active={(this.state.selectedSportsFilter === 'Soccer') ? true : false} href="#">Soccer</NavLink>
                      </NavItem>
                      {/* <NavItem onClick={(e)=>{this.filterBySports('EPL')}}> */}
                      {/*     <NavLink href="#">EPL</NavLink> */}
                      {/* </NavItem> */}
                  </Nav>
                </Col>
                <Col sm="12" md="6" className="text-right">
                  <Row>
                    <Col sm="12">
                      <Form inline className="ml-auto" className="float-right">
                        <FormGroup className="no-border mr-3">
                          <Input type="select" 
                                 name="sort_filter"
                                 id="sortFilter"
                                 onChange={this.handleSortSharp}
                                 value={this.state.sortType}>
                            <option value="" disabled>Sort By</option>
                            <option value="win_percent">Win {'%'}</option>
                            <option value="roi_amount">ROI Amount</option>
                            <option value="roi_percent">ROI {'%'}</option>
                            <option value="number_of_bets">Number of bets</option>
                            <option value="join_date">Join Date</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="no-border">
                            <Input type="text"
                                  value={this.state.sharpSearchText}
                                  placeholder="Search"
                                  onChange={(e)=>{
                                    this.setState({sharpSearchText: e.target.value.trim()}, ()=>{
                                      this.filterBySearch()
                                    });
                                  }}/>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                {
                  this.state.sharpsList.map((sharp, ind) => {
                    return (
                        <Col md="3" key={"sharp_"+ind}>
                          <Card className="card-profile">
                            <div className="card-image">
                              <h4 className="title">{sharp.first_name+" "+sharp.last_name }</h4>
                              <a href="#pablo" onClick={e => e.preventDefault()}>
                                <img
                                  alt="..."
                                  className="img img-raised rounded"
                                  onError={this.addDefaultSrc}
                                  src={sharp.img_url}
                                  width="256"
                                  height="256"
                                />
                              </a>
                            </div>
                            <CardBody>
                              <hr className="line-primary" />
                              <h3 className="job-title">Bets: {this.sharpFilteredSportsBets(sharp.bets)}</h3>
                              <Row>
                                <Col className="text-right"><small>Joined: {new Date(sharp.created_at._seconds * 1000).toLocaleDateString("en-US")}</small></Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Table className="tablesorter" responsive>
                                    <tbody>
                                      <tr>
                                        <td className="text-left">
                                          <i className="tim-icons icon-satisfied" />
                                          Win:
                                        </td>
                                        <td className="text-right">{(sharp.bets.length > 0) ? this.sharpWinPercentage(sharp.bets) : "0"}{"%"}</td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          <i className="tim-icons icon-money-coins" />
                                          ROI amount:
                                        </td>
                                        <td className="text-right">{(sharp.bets.length > 0) ? this.convertToCurrency(this.ROIAmount(sharp)) : "$0"}</td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          <i className="tim-icons icon-chart-pie-36" />
                                          ROI{'%'}:
                                        </td>
                                        <td className="text-right">{(sharp.bets.length > 0) ? this.ROIPercent(sharp)+"%" : "0%"}</td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          <i className="tim-icons icon-atom" />
                                          Avg bet amount:
                                        </td>
                                        <td className="text-right">{(sharp.bets.length > 0) ? this.sharpAvgBetAmount(sharp.bets) : "$0"}</td>
                                      </tr>
                                      {/* <tr>
                                        <td className="text-left">
                                          <i className="tim-icons icon-chart-bar-32" />
                                          Zoho Statistics:
                                        </td>
                                        <td className="text-right"><a href={sharp.zoho_stats} target="_blank">view more</a></td>
                                      </tr> */}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <Button color="info"
                                          onClick={()=>{
                                            window.open(sharp.zoho_stats, "_blank")
                                          }}>View Stats</Button>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col className="text-center">
                                  <Button color="primary"
                                          hidden = {this.hasSubscribed(sharp.id)}
                                          onClick={(e)=>{
                                            this.onSubscribeSelectSharp(sharp)
                                          }}>Subscribe</Button>
                                  <Button color="warning"
                                          hidden = {!this.hasSubscribed(sharp.id)}
                                          onClick={(e)=>{
                                            this.handleUnsubscribe(sharp.id)
                                          }}>Unsubscribe</Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                    )
                  })
                }
              </Row>
            </Container>
          </div>
        </div>


        <Modal isOpen={this.state.sharpViewModalOpen} toggle={this.toggleSharpViewModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleSharpViewModal}>{this.state.selectedSharp.first_name + " " +this.state.selectedSharp.last_name}</ModalHeader>
          <ModalBody>
            <Card>
              <Row>
                <Col><span className="float-left">Number of bets:</span> <span className="float-right">{this.state.selectedSharp.bets.length}</span></Col>
              </Row>
              <Row>
                <Col><span className="float-left">Win %:</span> <span className="float-right">{(this.state.selectedSharp.bets.length > 0) ? this.sharpWinPercentage(this.state.selectedSharp.bets) : "0"}</span></Col>
              </Row>
              <Row>
                <Col><span className="float-left">Total bet amount:</span> <span className="float-right">{(this.state.selectedSharp.bets.length > 0) ? this.sharpTotalBetAmount(this.state.selectedSharp.bets) : "$0"}</span></Col>
              </Row>
              <Row>
                <Col><span className="float-left">Average bet amount:</span> <span className="float-right">{(this.state.selectedSharp.bets.length > 0) ? this.sharpAvgBetAmount(this.state.selectedSharp.bets) : "$0"}</span></Col>
              </Row>
              <Row>
                <Col><span className="float-left">ROI amount:</span> <span className="float-right">{(this.state.selectedSharp.bets.length > 0) ? this.convertToCurrency(this.ROIAmount(this.state.selectedSharp)) : "$0"}</span></Col>
              </Row>
              <Row>
                <Col><span className="float-left">ROI %</span> <span className="float-right">{(this.state.selectedSharp.bets.length > 0) ? this.ROIPercent(this.state.selectedSharp)+"%" : "0%"}</span></Col>
              </Row>
              <Row>
                <Col><span className="float-left">Bank total:</span> <span className="float-right">{this.convertToCurrency(this.state.selectedSharp.bank_total)}</span></Col>
              </Row>
              <Row>
                <Col>
                  <a href={this.state.selectedSharp.zoho_stats} target="_blank">View More Statistics</a>
                </Col>
              </Row>
              <hr />
              {/* <Row>
                <Col><Line data={this.data} options={this.options} /></Col>
              </Row> */}
            </Card>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.sharpSubscribeModalOpen} toggle={this.toggleSharpSubscribeModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleSharpSubscribeModal}></ModalHeader>
            {
              !this.state.userHasDefaultPayment &&
              <>
                <ModalBody className="subscribe-modal">
                <Card>
                  <Row className="mt-2">
                    <Col><span className="float-left">Subscribe to:</span> <h3 className="float-right text-primary">{this.state.selectedSharp.first_name+" "+this.state.selectedSharp.last_name}</h3></Col>
                  </Row>
                  <Row className="mt-2">
                    <Col><span className="float-left">It appears you do not have a payment method attached to this account please enter your information below</span></Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Elements stripe={stripePromise}>
                        <InjectedCheckoutForm userInfo={this.state.user} sharpID={this.state.selectedSharp.id} subscribe={this.handleSharpSubscribe} />
                      </Elements>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col><span className="float-left">Fee:</span> <h3 className="float-right">{this.convertToCurrency(this.state.selectedSharp.subscription_price)}/{this.state.selectedSharp.subscription_duration}</h3></Col>
                  </Row>
                </Card>
                </ModalBody>
              </>
            }
            {
              this.state.userHasDefaultPayment &&
              <>
                <ModalBody className="subscribe-modal">
                  <Card>
                    <Row className="mt-2">
                      <Col><span className="float-left">Subscribe to:</span> <h3 className="float-right text-primary">{this.state.selectedSharp.first_name+" "+this.state.selectedSharp.last_name}</h3></Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input type="text"
                                  placeholder={"Default card ending in "+"... "+this.state.userCardInfo.last4}
                                  disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col><span className="float-left">Fee:</span> <h3 className="float-right">{this.convertToCurrency(this.state.selectedSharp.subscription_price)}/{this.state.selectedSharp.subscription_duration}</h3></Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Button size="sm" color="success" onClick={this.handleSharpSubscribe}>Subscribe</Button>
                      </Col>
                    </Row>
                  </Card>
                  </ModalBody>
              </>
            }
        </Modal>
      </>
    );
  }
}

export default Index;
