import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Spinner,
  Fade,
  Toast,ToastBody,ToastHeader,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Avatar from 'react-avatar';
import axios from 'axios';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import moment from 'moment';
import CSVReader from 'react-csv-reader'
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload";


class UsersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: 1,
      activeIndex: 0,
      subscriptionsCount: 0,
      subscriptions: [],
      isLoading: true,
      showToast: false,
      toastMessage: '',
      toastType: '',
      sharpsList: [],
      image: null,
      url: "",
      betSheetImportModalOpen: false,
      sharpAddModalOpen: false,
      sharpEditModalOpen: false,
      sharpDeleteModalOpen: false,
      selectedSharp: {},
      sharpCreationObj: {},
      betSheet: [],
      user: {
        id: '',
        card: {},
        subscriptions: []
      },
    };
  }
  
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log("auth user in nav: " + uid)
        this.state.user.id = uid
        this.refetchUserInfo()
        // ...
      } else {
        this.props.history.push({
          pathname: '/',
          state: { user: {} }
        })
      }
    });
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("users-page");

    // Retrieve list of sharps
  }

  componentWillUnmount() {
    document.body.classList.remove("profile-page");
  }

  refetchSharpsList = () => {
    this.setState({isLoading: true})
    axios.get('/bets')
      .then(resp => {
        this.setState({sharpsList: resp.data})
        this.setState({isLoading: false});
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }

  refetchUserInfo = () => {
    this.setState({isLoading: true})
    axios.post('/user', {uid: this.state.user.id})
      .then(resp => {
        let user  = resp.data
        if(user.is_admin){
          this.setState({user: resp.data, subscriptions: resp.data.subscriptions})
          axios.get('/bets')
          .then(resp => {
            this.setState({sharpsList: resp.data})
            this.setState({isLoading: false});
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              toastType: 'Error',
              toastMessage: err.message,
              showToast: true
            })
          })
        }else{
          this.props.history.push({
            pathname: '/',
            state: { user: {} }
          })
        }
        
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })
      })
  }



  //Handle logout
  handleLogout = () => {
    firebase.auth().signOut().then(() => {
      this.setState({user: {}})
      this.props.history.push({
        pathname: '/',
        state: { user: {} }
      })
    }).catch((error) => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: error.message,
        showToast: true
      })
    });
  }
  handleUsers = () => {
    this.props.history.push({
      pathname: '/users',
      state: { userInfo: this.state.user }
    })
  }

  handleHome = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }
  

  handleBets = () => {
    this.props.history.push({
      pathname: '/bets',
      state: { sharp: this.state.selectedSharp }
    })
  }

  handleProfile = () => {
    this.props.history.push({
      pathname: '/profile',
      state: { userInfo: this.state.user }
    })
  }

  handleImageUpload = (file) => {
    const { image } = this.state;
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var imagesRef = storageRef.child('images/' + Date.now() + file.name);
    const uploadTask = imagesRef.put(file);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        imagesRef
          .getDownloadURL()
          .then(url => {
            if(this.state.sharpEditModalOpen){
              this.setState({selectedSharp: {...this.state.selectedSharp, ...{img_url: url}}})
            }else{
              this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{img_url: url}}})
            }
          });
      }
    );
  };

  handleSharpCreation = () => {
    this.toggleSharpAddModal();
    this.setState({isLoading: true});
    this.state.sharpCreationObj.subscription_duration = "month"
    axios.post('/create_sharp', this.state.sharpCreationObj)
      .then(resp => {
        this.setState({
          toastType: 'Success',
          toastMessage: resp.data,
          showToast: true
        })

        this.refetchSharpsList()
        
        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          toastType: 'Error',
          toastMessage: err.message,
          showToast: true
        })

        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
  }

  // handleImageUpload = (file) => {
  //   // this.setState({
  //   //   imageFile: file
  //   // });
  //   // this.uploadImage();
  //   var storage = firebase.storage();
  //   var storageRef = storage.ref();
  //   var imagesRef = storageRef.child('images/'  + this.state.selectedSharp.id);



  //   imagesRef.put(file).then(function(snapshot) {
  //     console.log('Uploaded', snapshot.totalBytes, 'bytes.');
  //     console.log('File metadata:', snapshot.metadata);
  //     // Let's get a download URL for the file.
  //     snapshot.ref.getDownloadURL().then(function(url) {
  //       console.log('File available at', url);
  //     });
  //   }).catch(function(error) {
  //     // [START onfailure]
  //     console.error('Upload failed:', error);
  //     // [END onfailure]
  //   });


// Register three observers:
// 1. 'state_changed' observer, called any time the state changes
// 2. Error observer, called on failure
// 3. Completion observer, called on successful completion
    // uploadTask.on('state_changed', function(snapshot){
    //   // Observe state change events such as progress, pause, and resume
    //   // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //   var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   console.log('Upload is ' + progress + '% done');
    //   switch (snapshot.state) {
    //     case firebase.storage.TaskState.PAUSED: // or 'paused'
    //       console.log('Upload is paused');
    //       break;
    //     case firebase.storage.TaskState.RUNNING: // or 'running'
    //       console.log('Upload is running');
    //       break;
    //   }
    // }, function(error) {
    //   // Handle unsuccessful uploads
    // }, function() {
    //   // Handle successful uploads on complete
    //   // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //   uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
    //     console.log('File available at', downloadURL);
    //   });
    // });
 // }

  handleSharpImage = (downloadURL) =>{
    this.setState({selectedSharp: {...this.state.selectedSharp, ...{img_url: downloadURL}}})
  }

  handleSharpDelete = (id) => {
    this.toggleSharpDeleteModal();
    this.setState({isLoading: true});

    axios.post('/delete_sharp', {id: id})
      .then(resp => {
        this.setState({
          toastType: 'Success',
          toastMessage: resp.data,
          showToast: true
        })

        this.refetchSharpsList()
        
        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
      .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
  }

  handleSharpUpdate = () => {
    this.toggleSharpEditModal();
    this.setState({isLoading: true});

    axios.post('/update_sharp', this.state.selectedSharp)
      .then(resp => {
        this.setState({
          toastType: 'Success',
          toastMessage: resp.data,
          showToast: true
        })

        this.refetchSharpsList()
        
        setTimeout(()=>{
          this.setState({showToast: false});
        }, 3700)
      })
      .catch(err => {
          this.setState({
            isLoading: false,
            toastType: 'Error',
            toastMessage: err.message,
            showToast: true
          })

          setTimeout(()=>{
            this.setState({showToast: false});
          }, 3700)
        })
  }

  handleBetSheetImport = (data) => {
    const bets = data.filter((bet) => {
      if (bet.sharp !== null && bet.sharp.length > 1) {
        return bet
      }
    });

    this.setState({betSheet: bets})
  }

  handleSubmitBetSheet = () => {
    this.toggleBetSheetImportModal();
    this.setState({isLoading: true})
    axios.post('/import_bets', {bets: this.state.betSheet})
    .then(resp => {
      this.setState({
        isLoading: false,
        toastType: 'Success',
        toastMessage: resp.message,
        showToast: true
      })

      this.refetchSharpsList()

      setTimeout(()=>{
        this.setState({showToast: false});
      }, 3700)
    })
    .catch(err => {
      this.setState({
        isLoading: false,
        toastType: 'Error',
        toastMessage: err.message,
        showToast: true
      })

      setTimeout(()=>{
        this.setState({showToast: false});
      }, 3700)
    })
  }

  toggleBetSheetImportModal = () => {
    const {betSheetImportModalOpen} = this.state;

    (betSheetImportModalOpen) ? this.setState({betSheetImportModalOpen: false}) : this.setState({betSheetImportModalOpen: true})
  }

  toggleSharpAddModal = () => {
    const {sharpAddModalOpen} = this.state;

    (sharpAddModalOpen) ? this.setState({sharpAddModalOpen: false}) : this.setState({sharpAddModalOpen: true})
  }

  toggleSharpEditModal = () => {
    const {sharpEditModalOpen} = this.state;

    (sharpEditModalOpen) ? this.setState({sharpEditModalOpen: false}) : this.setState({sharpEditModalOpen: true})
  }

  toggleSharpDeleteModal = () => {
    const {sharpDeleteModalOpen} = this.state;

    (sharpDeleteModalOpen) ? this.setState({sharpDeleteModalOpen: false}) : this.setState({sharpDeleteModalOpen: true})
  }

  onEditSelectSharp = (sharp) => {
    const { selectedSharp } = this.state;

    this.setState({selectedSharp: {...selectedSharp, ...sharp}}, () => {
      this.toggleSharpEditModal()
    })
  }

  onSharpBets = (sharp) => {
    const { selectedSharp } = this.state;

    this.setState({selectedSharp: {...selectedSharp, ...sharp}}, () => {
      this.handleBets()
    })
  }

  

  onDeleteSelectSharp = (sharp) => {
    const { selectedSharp } = this.state;

    this.setState({selectedSharp: {...selectedSharp, ...sharp}}, () => {
      this.toggleSharpDeleteModal()
    })
  }

  convertToCurrency = (number) => {
    if(isNaN(parseFloat(number))){
        return "N/A"
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    return formatter.format(number)
  }

  sharpWinPercentage = (bets) => {
    let winCount = 0;

    bets.forEach((bet) => {
      if (bet.is_win) winCount++
    })

    return (<span>{((parseFloat(winCount/bets.length).toFixed(2))*100).toString()}</span>)
  }

  sharpOpenBets = (bets) => {
    let openBets = 0;

    bets.forEach((bet) => {
      if (bet.is_open) openBets++
    })

    return (<span>{openBets.toString()}</span>)
  }

  sharpUnsentBets = (bets) => {
    let unsentBets = 0;

    bets.forEach((bet) => {
      if (!bet.send_email) unsentBets++
    })

    return (<span>{unsentBets.toString()}</span>)
  }

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          .toLowerCase()
          .replace(/\W/g, '_')
    }

    return (
      <>
        {
          this.state.isLoading &&
          <>
            <div className="loading-window">
              <Card>
                <CardBody>
                  <Spinner color="primary" />
                </CardBody>
              </Card>
            </div>
          </>
        }
        <Fade in={this.state.showToast}>
          <Toast className="float-right">
            <ToastHeader icon={(this.state.toastType.toLowerCase() ==='success')?'success':'danger'}>
              {this.state.toastType}
            </ToastHeader>
            <ToastBody>
              {this.state.toastMessage}
            </ToastBody>
          </Toast>
        </Fade>
        <ColorNavbar title = "< Back" home={this.handleHome} userInfo={this.state.user} logout={this.handleLogout} profile={this.handleProfile} users={this.handleUsers} bets={this.handleBets} />
        <div className="wrapper mt-4" ref="wrapper">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Manage Sharps</h2>
            </Col>
          </Row>
          <Col>
            <Row>
              <Col className="text-right">
                <Button color="info" className="mr-4" onClick={() =>{this.toggleBetSheetImportModal()}}><i className="tim-icons icon-simple-add pr-2"></i>Import Bets</Button>
                <Button color="primary" onClick={() =>{this.toggleSharpAddModal()}}><i className="tim-icons icon-simple-add pr-2"></i> Add Sharp</Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="12">
                <ReactTable
                  data={this.state.sharpsList}
                  className="-striped -highlight bg-secondary p-2"
                  columns={[
                    {
                      Header:"Actions",
                      id: "Actions",
                      headerClassName: 'text-left font-weight-bold',
                      className: " text-left font-weight-bold",
                      Cell: row => {
                        return(
                          <>
                          <Button color="danger ml-2" size="sm" onClick={(e) => {
                            e.stopPropagation()
                            this.onEditSelectSharp(row.original)
                          }}>Edit</Button>
                          <Button color="primary" size="sm" onClick={(e) => {
                            e.stopPropagation()
                            this.onSharpBets(row.original)
                          }}>Bets</Button>
                          <Button color="info" size="sm" onClick={(e) => {
                            window.open(row.original.zoho_stats, "_blank")
                          }}>Stats</Button>
                          <Button color="default" size="sm" onClick={(e) => {
                            e.stopPropagation()
                            navigator.clipboard.writeText(row.original.id)
                          }}>Copy ID</Button>
                          </>
                        );
                      }
                    },
                    {
                      Header: "Username",
                      accessor: "username",
                      sort: true,
                      headerClassName: 'text-left font-weight-bold',
                      className: " text-left font-weight-bold"
                    },
                    {
                      Header: "Full Name",
                      sort: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center",
                      Cell: row => {
                        return(
                          <span>{row.original.first_name+" "+row.original.last_name}</span>
                        );
                      }
                    },
                    {
                      Header: "Phone #",
                      accessor: "phone",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold', 
                      className: " text-center p-2"
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center "
                    },
                    {
                      Header: "Total Bets",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center ",
                      Cell: row => {
                        return(
                          <span>{row.original.bets.length}</span>
                        );
                      }
                    },
                    {
                      Header: "Open Bets",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center ",
                      Cell: row => {
                        return(
                          <span>{this.sharpOpenBets(row.original.bets)}</span>
                        );
                      }
                    },
                    {
                      Header: "Bank Value",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center ",
                      Cell: row => {
                        return(
                          <span>{this.convertToCurrency(row.original.bank_total)}</span>
                        );
                      }
                    },
                    {
                      Header: "Win/Loss %",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center ",
                      Cell: row => {
                        return(
                          <span>{(row.original.bets.length > 0) ? this.sharpWinPercentage(row.original.bets) : "100"}%</span>
                        );
                      }
                    },
                    // {
                    //   Header: "Zoho link",
                    //   accessor: "zoho_stats",
                    //   sortable: true,
                    //   headerClassName: 'text-center font-weight-bold',
                    //   className: " text-center ",
                    //   Cell: row => {
                    //     return(
                    //       <a href={row.original.zoho_stats} target="_blank">{row.original.zoho_stats}</a>
                    //     );
                    //   }
                    // },
                    {
                      Header: "Unsent Bets",
                      sortable: true,
                      headerClassName: 'text-center font-weight-bold',
                      className: " text-center ",
                      Cell: row => {
                        return(
                          <span>{this.sharpUnsentBets(row.original.bets)}</span>
                        )
                      }
                    }
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </div>

        <Modal isOpen={this.state.betSheetImportModalOpen} toggle={this.toggleBetSheetImportModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleBetSheetImportModal}>Import  bets</ModalHeader>
          <ModalBody className="mt-4">
            <Card>
              <Row>
                <Col className="text-center">
                  <CSVReader
                    cssClass="csv-reader-input"
                    label="Select CSV to import bets for a given sharp"
                    onFileLoaded={this.handleBetSheetImport}
                    parserOptions={papaparseOptions}
                    inputId="ObiWan"
                    inputStyle={{color: 'red'}}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-center">
                  <Button color="primary" size="sm" onClick={(e) => {
                    e.stopPropagation()
                    this.handleSubmitBetSheet()
                  }}>Submit</Button>
                </Col>
              </Row>
            </Card>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.sharpAddModalOpen} toggle={this.toggleSharpAddModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleSharpAddModal}>Add new sharp</ModalHeader>
          <ModalBody className="mt-4">
            <Card>
            <Col xs={12} sm={4} md={3}>
                <ImageUpload avatar updateImageFile = {this.handleImageUpload}/>
              </Col>
              <Row>
                <Col>
                  <Form>
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input type="text" onChange={(e)=>{
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{first_name: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input type="text" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{last_name: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Username</Label>
                      <Input type="text" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{username: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input type="email" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{email: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Phone</Label>
                      <Input type="tel" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{phone: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Starting Bank Total</Label>
                      <Input type="text" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{bank_total: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Zoho url</Label>
                      <Input type="text" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{zoho_stats: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Stripe Product</Label>
                      <Input type="text" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{subscription_id: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Price</Label>
                      <Input type="number" onChange={(e) => {
                          this.setState({sharpCreationObj: {...this.state.sharpCreationObj, ...{subscription_price: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Col className="text-right">
                        <Button color="secondary" size="sm" onClick={()=>{this.toggleSharpAddModal()}}>Cancel</Button>
                        <Button color="primary" size="sm" className="ml-2" onClick={()=>{
                          this.handleSharpCreation()
                        }}>Submit</Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Card>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.sharpEditModalOpen} toggle={this.toggleSharpEditModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleSharpEditModal}>Edit {this.state.selectedSharp.first_name + " " +this.state.selectedSharp.last_name}</ModalHeader>
          <ModalBody className="mt-4">
            <Card>
            <Col xs={12} sm={4} md={3}>
                <ImageUpload avatar updateImageFile={this.handleImageUpload}/>
              </Col>
              <Row>
                <Col>
                  <Form>
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input type="text" value={this.state.selectedSharp.first_name} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{first_name: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input type="text" value={this.state.selectedSharp.last_name} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{last_name: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Username</Label>
                      <Input type="text" value={this.state.selectedSharp.username} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{username: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input type="email" value={this.state.selectedSharp.email} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{email: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Phone</Label>
                      <Input type="tel" value={this.state.selectedSharp.phone} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{phone: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Zoho link</Label>
                      <Input type="text" value={this.state.selectedSharp.zoho_stats} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{zoho_stats: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Stripe Product</Label>
                      <Input type="text" value={this.state.selectedSharp.subscription_id} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{subscription_id: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Price</Label>
                      <Input type="number" value={this.state.selectedSharp.subscription_price} onChange={(e)=>{
                          this.setState({selectedSharp: {...this.state.selectedSharp, ...{subscription_price: e.target.value}}})
                        }} />
                    </FormGroup>
                    <FormGroup>
                      <Col className="text-right">
                        <Button color="secondary" size="sm" onClick={()=>{this.toggleSharpEditModal()}}>Cancel</Button>
                        <Button color="primary" size="sm" className="ml-2" onClick={()=>{
                            this.handleSharpUpdate()
                          }}>Submit</Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Card>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.sharpDeleteModalOpen} toggle={this.toggleSharpDeleteModal} modalClassName="modal-black">
          <ModalHeader toggle={this.toggleSharpDeleteModal}></ModalHeader>
          <ModalBody className="mt-4">
            <Card>
              <h2 className="text-center">Are you sure you want to remove</h2>
              <h1 className="text-center"><span className="text-danger">{this.state.selectedSharp.first_name + " " +this.state.selectedSharp.last_name}</span> ? </h1>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Col className="text-center">
              <Button color="secondary" size="sm" className="mr-1" onClick={()=>{this.toggleSharpDeleteModal()}}>Cancel</Button>
              <Button color="primary" size="sm" className="ml-1" onClick={() =>{this.handleSharpDelete(this.state.selectedSharp.id)}}>Confirm</Button>
            </Col>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default UsersPage;
